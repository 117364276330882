import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { StudentService } from 'src/app/shared/service/student.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Student } from 'src/app/shared/model/student.model';
import { ApplicationService } from 'src/app/shared/service/application.service';
import { FormControl, FormGroup } from '@angular/forms';
import {AuthenticationService} from '../../../shared/service/authentication.service';

@Component({
  selector: 'app-luid-search',
  templateUrl: './luid-search.component.html',
  styles: [
    '.luid_search_row { align-items: center; }',
    '.btn { padding: 9px 16px; }',
    '#luid_search_field { width: 270px; }'
  ]
})
export class LuidSearchComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});

  @ViewChild('luidSearchInput') luidSearchInput: ElementRef;

  searchSucceeded = false;
  searchFinished = false;
  searching = false;
  preloaded = false;
  errorMessage = '';
  theStudent: Student;
  luidPattern = '^L\\d{8}';
  luid = '';

  constructor(
    public studentService: StudentService,
    private applicationService: ApplicationService,
    private authService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.formGroup.addControl('luid_prefill_happened', new FormControl<string>('') );
  }

  getStudentByID(event?: Event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.luidSearchInput.nativeElement.validity.valid){
      this.searchSucceeded = false;
      this.searchFinished = true;
      this.searching = false;
      this.errorMessage = 'Please enter a valid LUID before searching. Example: L00012345';
      return false;
    }

    const searchedLuid = this.luidSearchInput.nativeElement.value;
    this.preloaded = false;
    this.theStudent = null;
    if (searchedLuid === '') return false;
    this.searching = true;
    this.searchFinished = false;

    this.studentService.luidSearch(searchedLuid).subscribe({
      next: (student: Student) => {
        if (!student.pidm || student.pidm.length === 0) {
          this.searchSucceeded = false;
          this.errorMessage = 'No Contact record found with an ID of ' + searchedLuid;
        } else {
          this.theStudent = student;
          this.searchSucceeded = true;
          this.luid = searchedLuid;
        }
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.errorMessage = 'No Contact record found with an ID of ' + searchedLuid;
        } else if (error.status === 401 || error.status === 403) {
          this.errorMessage = 'Unauthorized';
        } else if (error.status === 503) {
          this.errorMessage = 'Sorry, the LU-ID Service is unavailable';
        } else {
          this.errorMessage = 'Sorry, there was an error with this request, please reach out to support';
        }
        this.searchSucceeded = false;
        this.searchFinished = true;
        this.searching = false;
      },
      complete: () => {
        this.searching = false;
        this.searchFinished = true;
      }
    });


  }

  studentPrefillApp(student: Student) {
    if (this.luid)
      this.authService.studentLUID = this.luid;
    this.formGroup.get('luid_prefill_happened').setValue('Yes');
    this.studentService.emitStudent.next(student);
    this.preloaded = true;
  }

}


